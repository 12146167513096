import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {SLIDE_UP, XL} from "react-ladda";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import InternalTextBanner from "./../components/internalTextBanner";
import {Gen} from "../helpers/gen";
import TemplateData from "../helpers/TemplateData";
import EditTemplateComponent from "../components/EditTemplateComponent";
import ApplicationComponent from "../components/ApplicationComponent";
import TruckComponent from "../components/TruckComponent";
import Loader from "../components/Loader";
import PresentationComponent from "../components/PresentationComponent";

const placeHolderForTrucksTemplate = {featureImages: true, videos: false, contentText: true, featuresText: true, sharableContent:true};
const placeHolderForApplicationTemplate = {featureImages: true, videos: true, contentText: true, featuresText: true};
const placeHolderForTruckAndApplicationTemplate = {Video : true };
const placeHolderForPresentationTemplate = {pdf : true};
class AppFlowPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {navigationData:[]}
		};
	}

	async componentWillMount() {
		this.getData();
	}

	async getData(path) {
		this.pathData = await Gen.getContentForPath(path || []);
		this.resolveViewForTemplate(this.pathData);
	}

	resolveViewForTemplate = pathData => {
        if (pathData.USA) {
			const data = {navigationData:[
				{
					icon: "",
					title: "USA",
					text: "Imperial"
				},
				{
					icon: "",
					title: "UK",
					text: "Metric"
				}
			]};
			return this.setState({data});
		}
		switch (pathData.template) {
			case TemplateData.applicationContentTemplateScreen:
				this.setState({data: pathData, template: pathData.template, placeHolder: placeHolderForApplicationTemplate});
				break;
			case TemplateData.trucksContentTemplateScreen:
				this.setState({data: pathData, template: pathData.template, placeHolder: placeHolderForTrucksTemplate});
				break;
			case TemplateData.aboutUsPresentationScreen:
        this.setState({data: pathData, template: pathData.template, placeHolder: placeHolderForPresentationTemplate});
        break;
      case TemplateData.mainScreenGreenIconTabTemplate:
			case TemplateData.mainScreenGreenIconGridTemplate:
			case TemplateData.mainScreenGreyIconGridTemplate:
			case TemplateData.mainScreenGreyIconTabTemplate: {
				let videoData;
                if (pathData.path[1] === 'Trucks' || pathData.path[1] === 'Applications') {
                    videoData = pathData;
                }
				const navigationData = pathData["content"]["tab"]
					? Object.values(pathData["content"]["tab"])
					: Object.values(pathData["content"]);
				return this.setState({
					data : {navigationData,videoData},
					placeHolder: placeHolderForTruckAndApplicationTemplate,

				});
			}
			default: {
				this.setState({data: "Content is not yet editable"});
			}
		}
	};


	wrapInLoader = (func) => {
		if (!Gen.disabledClick) {
			Gen.disabledClick = true;
			Gen.showLoader();
			func();
			setTimeout(() => {
				Gen.disabledClick = false;
				Gen.hideLoader();
			}, 2000);
		}
	};

	goToScreen = title => {
		//for application inside trucks
		if(title === "Applications" && this.path[this.path.length - 1] === btoa('Trucks')){
			this.path[this.path.length-1] = btoa('Applications')
		}
		else
		this.path = Gen.getPathOnItemClick(this.path || [], title);
		this.getData(this.path);
	};

	goBack = () => {
		this.path = Gen.getPathOnBackPress(this.path);
		this.getData(this.path);
	};

	head() {
		return (
			<Helmet>
				<title>{`App Flow`}</title>
			</Helmet>
		);
	}

	renderTemplate = () => {
		const {data, template} = this.state;
		switch (template) {
			case TemplateData.applicationContentTemplateScreen:
				return <ApplicationComponent data={data}/>;
			case TemplateData.trucksContentTemplateScreen:
				return <TruckComponent data={data}/>;
			case TemplateData.aboutUsPresentationScreen:
				return <PresentationComponent data = {data}/>
		}
	};

	updateTemplateView = async formValues => {
        if (formValues["featureImages"] && formValues["featureImages"].length > 0) {
            formValues["featureImages"] = Gen.getDefaultNameForVideoOrImage(
                formValues["featureImages"]
            );
        }
        if (formValues["videos"] && formValues["videos"].length > 0) {
            formValues["videos"] = Gen.getDefaultNameForVideoOrImage(
                formValues["videos"]
            );
        }
        this.addOrRemoveVideo(formValues);
        const data = Object.assign(this.pathData, formValues);
        await Gen.updateDataOnPath(this.path || [], data);
        this.getData(this.path)

    };

	isContentEditable=(data)=> {
		if(data.navigationData){
			return !!data.videoData;
		}
        else
        	return typeof data === 'object';

	};

	addOrRemoveVideo = (formValues) => {
        let obj,contentArray;
        if (this.pathData["content"]) {
            obj = this.pathData["content"]["tab"] || this.pathData["content"];
            contentArray = Gen.getArrayFromObject(obj);
        }
        if (formValues['Video']) {
            let lastPathKey = Object.keys(this.pathData.path).length;
            let path = this.pathData.path;
            if(this.pathData.path[lastPathKey-1] !== 'Video')
                path[lastPathKey] = "Video";
            let url = formValues['Video'];
            formValues['Video'] = {
                path: path,
                template: TemplateData.applicationContentTemplateScreen,
                videos: {
                    0:
                        {
                            name: "default_name",
                            url: url
                        }
                }
            };
            if(obj[0].title !== 'Video') {
                let icon;
                if (this.pathData.template === TemplateData.mainScreenGreenIconGridTemplate ||
                    this.pathData.template === TemplateData.mainScreenGreenIconTabTemplate
                )
                    icon = "play_white";
                else
                    icon = "play_green";
                contentArray.unshift({icon: icon, title: "Video"});
                this.updatePathDataContent(contentArray);
            }
        } else {
            if (this.pathData.Video)
                delete this.pathData.Video;
            if (obj && obj[0].title === 'Video') {
                contentArray.splice(0, 1);
                this.updatePathDataContent(contentArray)
            }
        }
	};

	updatePathDataContent = (contentArray) => {
        if (this.pathData["content"]["tab"]) {
            this.pathData["content"]["tab"] = contentArray;
            this.pathData["content"]["mobile"] = contentArray;
        } else
            this.pathData["content"] = contentArray;
	};

	renderGrid = (item , index) => {
        const {icon, title, text} = item;
        return(
            title !== 'Video' && <div
                key={index}
                className="item-container"
                onClick={() => this.wrapInLoader(() => this.goToScreen(title))}
            >
                <img
                    className="nav-icons"
                    src={Gen.getIcon([], icon)}
					alt={'icon'}
                />
                <div className="nav-title">{text || title}</div>
            </div>
		)
	}

	render() {
		const {data} = this.state;
		return (
			<section className="contactPage_wrap">
				{this.head()}
				<InternalTextBanner Heading="App Flow"
				                    SubHeading="Also make sure you upload videos/images of minimum size. Otherwise the app won't run smoothly!"
				                    wrapperClass="contact"/>
				<ReactCSSTransitionGroup
					transitionName="anim"
					transitionAppear={true}
					transitionAppearTimeout={5000}
					transitionEnter={false}
					transitionLeave={false}
				>
					<div className="main anim-appear app-flow">
						<div className="mobile-view-wrapper row">
							<div className={"col col-md-6 col-sm-12 col-center"}>
								<Loader ref={(ref) => Gen.initializeLoaderRef(ref)}/>
								{this.path &&
								this.path.length > 0 && (
									<div className="back-button" onClick={() => this.wrapInLoader(() => this.goBack())}>
                                        <i style={{fontSize: 24, padding: 5}} className="fas fa-arrow-left"/>
                                    </div>
                                )}
                                <div className="mobile-view">
                                    {typeof data === "string" ? (
                                        <div> {data} </div>
                                    ) : data.navigationData ? data.videoData && data.videoData.Video ?
                                        <React.Fragment>
                                            <video width='100%' height="40%" controls>
                                                <source src={data.videoData.Video.videos[0].url} type="video/mp4"/>
                                            </video>
                                            {data.navigationData.map((item, index) => this.renderGrid(item, index))}
                                        </React.Fragment>
                                        :
                                        data.navigationData.map((item, index) => this.renderGrid(item, index))
                                        : (
                                            this.renderTemplate()
                                        )}
                                </div>
                            </div>
                            <div className={"col col-md-6 col-sm-12 col-center"}>
								<div className={"edit-view"}>
									{this.isContentEditable(data) ?
										<EditTemplateComponent
											updateTemplateView={this.updateTemplateView}
											data={data.videoData || data}
											placeHolder={this.state.placeHolder}
										/> :
										<div className={"col-center non-editable"}> Content is not yet editable</div>
									}
								</div>
							</div>
						</div>
					</div>
				</ReactCSSTransitionGroup>
			</section>
		);
	}
}

export default {
	component: AppFlowPage
};
