const TemplateData = {
	// Tabs
	mainScreenGreenIconTabTemplate: "Main Screen Green Icon Tab Template",
	mainScreenGreyIconTabTemplate: "Main Screen Grey Icon Tab Template",
	mainScreenVideoContentImageLinksTabTemplate: "Main Screen Video Content Image Links Tab Template",
	mainScreenGreyIconGridTemplate: "Main Screen Grey Icon Grid Template",
	mainScreenGreenIconGridTemplate: "Main Screen Green Icon Grid Template",
	mainScreenVideoOnlyTabTemplate: "Main Screen Video Only Tab Template",
	mainScreenVideoContentImageTabTemplate: "Main Screen Video Content Image Tab Template",
	contactDetailsTemplate: "Contact Details Template",
	salesTeamTemplate: "Sales Team Template",
	applicationContentTemplateScreen: "Application Content Template Screen",
	trucksContentTemplateScreen: "Trucks Content Template Screen",
	siteSurveyTemplateScreen: "Site Survey Template Screen",
	aisleWidthTemplateScreen: "Aisle Width Template Screen",
	keyFactsTemplateScreen: 'Key Facts Template Screen',
	keyMessageTemplateScreen: 'Key Message Template Screen',
	timelineHistoryTemplateScreen: 'Timeline History Template Screen',
	aboutUsPresentationScreen: 'About Us Presentation Screen'
};

export default TemplateData;