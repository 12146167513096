
class StaticImageResource {
	static getLocalImageForUSA(imageName) {
		switch (imageName) {
      case 'presentation_white':
        return '/assets/USA/images/presentation_white.png';
			case 'cbe_8000_truck':
				return '/assets/USA/images/cbe_8000_truck.png';
			case 'cbe_4000_truck':
				return '/assets/USA/images/cbe_4000_truck.png';
			case 'cb_6000_truck':
				return '/assets/USA/images/cb_6000_truck.png';
			case 'cb_5000_truck':
				return '/assets/USA/images/cb_5000_truck.png';
			case 'cb_3000_truck':
				return '/assets/USA/images/cb_3000_truck.png';
			case 'cb_2500_truck':
				return '/assets/USA/images/cb_2500_truck.png';
			case 'cbe_6000_truck':
				return '/assets/USA/images/cbe_6000_truck.png';
			case 'cbe_5000_truck':
				return '/assets/USA/images/cbe_5000_truck.png';
			case 'cbe_3000_truck':
				return '/assets/USA/images/cbe_3000_truck.png';
			case 'cbe_2500_truck':
				return '/assets/USA/images/cbe_2500_truck.png';
			case 'c9000_truck':
				return '/assets/USA/images/c9000_truck.png';
			case 'c8000_truck':
				return '/assets/USA/images/c8000_truck.png';
			case 'c7000_truck':
				return '/assets/USA/images/c7000_truck.png';
			case 'c6000_truck':
				return '/assets/USA/images/c6000_truck.png';
			case 'c6000_compact_truck':
				return '/assets/USA/images/c6000_compact_truck.png';
			case 'c5000_compact_truck':
				return '/assets/USA/images/c5000_compact_truck.png';
			case 'c4800_truck':
				return '/assets/USA/images/c4800_truck.png';
			case 'c4500_truck':
				return '/assets/USA/images/c4500_truck.png';
			case 'c4000_truck':
				return '/assets/USA/images/c4000_truck.png';
			case 'c3500_truck':
				return '/assets/USA/images/c3500_truck.png';
			case 'c3000_truck':
				return '/assets/USA/images/c3000_truck.png';
			case 'c2500_truck':
				return '/assets/USA/images/c2500_truck.png';
			case 'cb_8000_truck':
				return '/assets/USA/images/cb_8000_truck.png';
			case 'cb_4000_truck':
				return '/assets/USA/images/cb_4000_truck.png';
			case 'c6000_fwsl_truck':
				return '/assets/USA/images/c6000_fwsl_truck.png';
			case 'c5000_fwsl_truck':
				return '/assets/USA/images/c5000_fwsl_truck.png';
			case 'c12000_fwsl_truck':
				return '/assets/USA/images/c12000_fwsl_truck.png';
			case 'c10000_fwsl_truck':
				return '/assets/USA/images/c10000_fwsl_truck.png';
			case 'c6000_gte_truck':
				return '/assets/USA/images/c6000_gte_truck.png';
			case 'c6000_gt_truck':
				return '/assets/USA/images/c6000_gt_truck.png';
			case 'c4500_gte_truck':
				return '/assets/USA/images/c4500_gte_truck.png';
			case 'c4500_gt_truck':
				return '/assets/USA/images/c4500_gt_truck.png';
			case 'c3000_gte_truck':
				return '/assets/USA/images/c3000_gte_truck.png';
			case 'c10000_gte_truck':
				return '/assets/USA/images/c10000_gte_truck.png';
			case 'c10000_gt_truck':
				return '/assets/USA/images/c10000_gt_truck.png';
			case 'c7000et_truck':
				return '/assets/USA/images/c7000et_truck.png';
			case 'c6000et_truck':
				return '/assets/USA/images/c6000et_truck.png';
			case 'c3500et_truck':
				return '/assets/USA/images/c3500et_truck.png';
			case 'c3000et_truck':
				return '/assets/USA/images/c3000et_truck.png';
			case 'c9000et_truck':
				return '/assets/USA/images/c9000et_truck.png';
			case 'c8000et_truck':
				return '/assets/USA/images/c8000et_truck.png';
			case 'c4500et_truck':
				return '/assets/USA/images/c4500et_truck.png';
			case 'c4000et_truck':
				return '/assets/USA/images/c4000et_truck.png';
			case 'c5500et_truck':
				return '/assets/USA/images/c5500et_truck.png';
			case 'c5000et_truck':
				return '/assets/USA/images/c5000et_truck.png';
			case 'c12000et_truck':
				return '/assets/USA/images/c12000et_truck.png';
			case 'c11000et_truck':
				return '/assets/USA/images/c11000et_truck.png';
			case 'c10000et_truck':
				return '/assets/USA/images/c10000et_truck.png';
			case 'c5500xl_truck':
				return '/assets/USA/images/c5500xl_truck.png';
			case 'c5000xl_truck':
				return '/assets/USA/images/c5000xl_truck.png';
			case 'c12000xl_truck':
				return '/assets/USA/images/c12000xl_truck.png';
			case 'c1000_truck':
				return '/assets/USA/images/c1000_truck.png';
			case 'c10000xl_truck':
				return '/assets/USA/images/c10000xl_truck.png';
			case 'c22000_truck':
				return '/assets/USA/images/c22000_truck.png';
			case 'c26000h_truck':
				return '/assets/USA/images/c26000h_truck.png';
			case 'c26000_truck':
				return '/assets/USA/images/c26000_truck.png';
			case 'c12000h_truck':
				return '/assets/USA/images/c12000h_truck.png';
			case 'c12000_truck':
				return '/assets/USA/images/c12000_truck.png';
			case 'c35000_truck':
				return '/assets/USA/images/c35000_truck.png';
			case 'c30000_truck':
				return '/assets/USA/images/c30000_truck.png';
			case 'c16000_truck':
				return '/assets/USA/images/c16000_truck.png';
			case 'c55000_truck':
				return '/assets/USA/images/c55000_truck.png';
			case 'c50000_truck':
				return '/assets/USA/images/c50000_truck.png';
			case 'c25000_truck':
				return '/assets/USA/images/c25000_truck.png';
			case 'c20000_truck':
				return '/assets/USA/images/c20000_truck.png';
			case 'c17300_truck':
				return '/assets/USA/images/c17300_truck.png';
			case 'c14000_truck':
				return '/assets/USA/images/c14000_truck.png';
			case 'engineering_wood_white':
				return '/assets/USA/images/engineering_wood_white.png';
			case 'wr_truck':
				return '/assets/USA/images/wr_truck.png';
			case 'wr4_truck':
				return '/assets/USA/images/wr4_truck.png';
			case 'wr4':
				return '/assets/USA/images/wr4.png';
			case 'wr':
				return '/assets/USA/images/wr.png';
			case 'woodwork_millwork_white':
				return '/assets/USA/images/woodwork_millwork_white.png';
			case 'woodwork_millwork_green':
				return '/assets/USA/images/woodwork_millwork_green.png';
			case 'warehousing_white':
				return '/assets/USA/images/warehousing_white.png';
			case 'warehousing_green':
				return '/assets/USA/images/warehousing_green.png';
			case 'warehouse_white':
				return '/assets/USA/images/warehouse_white.png';
			case 'warehouse_design_white':
				return '/assets/USA/images/warehouse_design_white.png';
			case 'warehouse_design_green':
				return '/assets/USA/images/warehouse_design_green.png';
			case 'wall_panels_timberframe_white':
				return '/assets/USA/images/wall_panels_timberframe_white.png';
			case 'wall_panels_timberframe_green':
				return '/assets/USA/images/wall_panels_timberframe_green.png';
			case 'vinyl_siding_roofing_white':
				return '/assets/USA/images/vinyl_siding_roofing_white.png';
			case 'vinyl_siding_roofing_green':
				return '/assets/USA/images/vinyl_siding_roofing_green.png';
			case 'video':
				return '/assets/USA/images/video.png';
			case 'trusses_white':
				return '/assets/USA/images/trusses_white.png';
			case 'trusses_green':
				return '/assets/USA/images/trusses_green.png';
			case 'trucks_white':
				return '/assets/USA/images/trucks_white.png';
			case 'trucks_green':
				return '/assets/USA/images/trucks_green.png';
			case 'timeline_history_white':
				return '/assets/USA/images/timeline_history_white.png';
			case 'timberframe_green':
				return '/assets/USA/images/timberframe_green.png';
			case 'testimonials_white':
				return '/assets/USA/images/testimonials_white.png';
			case 'testimonials_green':
				return '/assets/USA/images/testimonials_green.png';
			case 'testimonial_green':
				return '/assets/USA/images/testimonial_green.png';
			case 'technical_support_white':
				return '/assets/USA/images/technical_support_white.png';
			case 'technical_support_green':
				return '/assets/USA/images/technical_support_green.png';
			case 'straddle_carrier':
				return '/assets/USA/images/straddle_carrier.png';
			case 'storage_white':
				return '/assets/USA/images/storage_white.png';
			case 'storage_green':
				return '/assets/USA/images/storage_green.png';
			case 'steel_service_center_stockholders_white':
				return '/assets/USA/images/steel_service_center_stockholders_white.png';
			case 'steel_service_center_stockholders_green':
				return '/assets/USA/images/steel_service_center_stockholders_green.png';
			case 'steel_pipe_white':
				return '/assets/USA/images/steel_pipe_white.png';
			case 'steel_pipe_green':
				return '/assets/USA/images/steel_pipe_green.png';
			case 'steel_aluminium_white':
				return '/assets/USA/images/steel_aluminium_white.png';
			case 'steel_aluminium_green':
				return '/assets/USA/images/steel_aluminium_green.png';
			case 'stand_up_truck':
				return '/assets/USA/images/stand_up_truck.png';
			case 'st_truck':
				return '/assets/USA/images/st_truck.png';
			case 'st':
				return '/assets/USA/images/st.png';
			case 'speciality_metals_stainless_steel_white':
				return '/assets/USA/images/speciality_metals_stainless_steel_white.png';
			case 'speciality_metals_stainless_steel_green':
				return '/assets/USA/images/speciality_metals_stainless_steel_green.png';
			case 'sl':
				return '/assets/USA/images/sl.png';
			case 'site_survey_white':
				return '/assets/USA/images/site_survey_white.png';
			case 'site_survey_green':
				return '/assets/USA/images/site_survey_green.png';
			case 'sc_truck':
				return '/assets/USA/images/sc_truck.png';
			case 'sc':
				return '/assets/USA/images/sc.png';
			case 'sales_team_white':
				return '/assets/USA/images/sales_team_white.png';
			case 'sales_team_green':
				return '/assets/USA/images/sales_team_green.png';
			case 'safety_white':
				return '/assets/USA/images/safety_white.png';
			case 'safety_storage_efficiency_yellow':
				return '/assets/USA/images/safety_storage_efficiency_yellow.png';
			case 'safety_storage_efficiency_white':
				return '/assets/USA/images/safety_storage_efficiency_white.png';
			case 'safety_storage_efficiency_green':
				return '/assets/USA/images/safety_storage_efficiency_green.png';
			case 'safety_green':
				return '/assets/USA/images/safety_green.png';
			case 'rt_truck':
				return '/assets/USA/images/rt_truck.png';
			case 'rt':
				return '/assets/USA/images/rt.png';
			case 'rough_terrain_white':
				return '/assets/USA/images/rough_terrain_white.png';
			case 'rough_terrain_green':
				return '/assets/USA/images/rough_terrain_green.png';
			case 'roll_forming_white':
				return '/assets/USA/images/roll_forming_white.png';
			case 'roll_forming_green':
				return '/assets/USA/images/roll_forming_green.png';
			case 'renewables_white':
				return '/assets/USA/images/renewables_white.png';
			case 'renewables_green':
				return '/assets/USA/images/renewables_green.png';
			case 'rebar_white':
				return '/assets/USA/images/rebar_white.png';
			case 'rebar_green':
				return '/assets/USA/images/rebar_green.png';
			case 'precast_white':
				return '/assets/USA/images/precast_white.png';
			case 'precast_green':
				return '/assets/USA/images/precast_green.png';
			case 'ppt_truck':
				return '/assets/USA/images/ppt_truck.png';
			case 'ppt':
				return '/assets/USA/images/ppt.png';
			case 'play_white':
				return '/assets/USA/images/play_white.png';
			case 'play_green':
				return '/assets/USA/images/play_green.png';
			case 'pedestrian_truck':
				return '/assets/USA/images/pedestrian_truck.png';
			case 'operator_training_white':
				return '/assets/USA/images/operator_training_white.png';
			case 'op_truck':
				return '/assets/USA/images/op_truck.png';
			case 'op':
				return '/assets/USA/images/op.png';
			case 'oil_gas_white':
				return '/assets/USA/images/oil_gas_white.png';
			case 'oil_gas_energy_white':
				return '/assets/USA/images/oil_gas_energy_white.png';
			case 'oil_gas_energy_green':
				return '/assets/USA/images/oil_gas_energy_green.png';
			case 'north_american_team_white':
				return '/assets/USA/images/north_american_team_white.png';
			case 'north_american_team_green':
				return '/assets/USA/images/north_american_team_green.png';
			case 'north_american_contacts_white':
				return '/assets/USA/images/north_american_contacts_white.png';
			case 'north_american_contacts_green':
				return '/assets/USA/images/north_american_contacts_green.png';
			case 'multi_directional_truck':
				return '/assets/USA/images/multi_directional_truck.png';
			case 'mr_truck':
				return '/assets/USA/images/mr_truck.png';
			case 'mr':
				return '/assets/USA/images/mr.png';
			case 'mg_truck':
				return '/assets/USA/images/mg_truck.png';
			case 'mg':
				return '/assets/USA/images/mg.png';
			case 'manufacturing_white':
				return '/assets/USA/images/manufacturing_white.png';
			case 'manufacturing_green':
				return '/assets/USA/images/manufacturing_green.png';
			case 'lumber_white':
				return '/assets/USA/images/lumber_white.png';
			case 'lumber_green':
				return '/assets/USA/images/lumber_green.png';
			case 'lumbar_white':
				return '/assets/USA/images/lumbar_white.png';
			case 'lr_truck':
				return '/assets/USA/images/lr_truck.png';
			case 'key_message_white':
				return '/assets/USA/images/key_message_white.png';
			case 'key_facts_white':
				return '/assets/USA/images/key_facts_white.png';
			case 'hq_contacts_white':
				return '/assets/USA/images/hq_contacts_white.png';
			case 'hq_contacts_green':
				return '/assets/USA/images/hq_contacts_green.png';
			case 'hollowcore_white':
				return '/assets/USA/images/hollowcore_white.png';
			case 'hollowcore_green':
				return '/assets/USA/images/hollowcore_green.png';
			case 'heavy_manufacturing_white':
				return '/assets/USA/images/heavy_manufacturing_white.png';
			case 'heavy_manufacturing_green':
				return '/assets/USA/images/heavy_manufacturing_green.png';
			case 'heat_treatment_white':
				return '/assets/USA/images/heat_treatment_white.png';
			case 'heat_treatment_green':
				return '/assets/USA/images/heat_treatment_green.png';
			case 'gte_6000_truck':
				return '/assets/USA/images/gte_6000_truck.png';
			case 'gt_truck':
				return '/assets/USA/images/gt_truck.png';
			case 'gt_6000_truck':
				return '/assets/USA/images/gt_6000_truck.png';
			case 'gt_10000_truck':
				return '/assets/USA/images/gt_10000_truck.png';
			case 'gt':
				return '/assets/USA/images/gt.png';
			case 'glavanising_white':
				return '/assets/USA/images/glavanising_white.png';
			case 'galvanising_white':
				return '/assets/USA/images/galvanising_white.png';
			case 'galvanising_green':
				return '/assets/USA/images/galvanising_green.png';
			case 'food_and_drink_white':
				return '/assets/USA/images/food_and_drink_white.png';
			case 'food_and_drink_green':
				return '/assets/USA/images/food_and_drink_green.png';
			case 'fabrication_white':
				return '/assets/USA/images/fabrication_white.png';
			case 'fabrication_green':
				return '/assets/USA/images/fabrication_green.png';
			case 'esl_truck':
				return '/assets/USA/images/esl_truck.png';
			case 'engineered_wood_white':
				return '/assets/USA/images/engineered_wood_white.png';
			case 'engineered_wood_green':
				return '/assets/USA/images/engineered_wood_green.png';
			case 'efficiencies_white':
				return '/assets/USA/images/efficiencies_white.png';
			case 'efficiencies_green':
				return '/assets/USA/images/efficiencies_green.png';
			case 'distribution_white':
				return '/assets/USA/images/distribution_white.png';
			case 'distribution_green':
				return '/assets/USA/images/distribution_green.png';
			case 'cs_truck':
				return '/assets/USA/images/cs_truck.png';
			case 'cs_850_truck':
				return '/assets/USA/images/cs_850_truck.png';
			case 'cs_4400_truck':
				return '/assets/USA/images/cs_4400_truck.png';
			case 'cs_3850_truck':
				return '/assets/USA/images/cs_3850_truck.png';
			case 'cs_3200_truck':
				return '/assets/USA/images/cs_3200_truck.png';
			case 'cs_2750_truck':
				return '/assets/USA/images/cs_2750_truck.png';
			case 'cs_2200_truck':
				return '/assets/USA/images/cs_2200_truck.png';
			case 'cs_2000_truck':
				return '/assets/USA/images/cs_2000_truck.png';
			case 'cs_1870_truck':
				return '/assets/USA/images/cs_1870_truck.png';
			case 'cs_1750_truck':
				return '/assets/USA/images/cs_1750_truck.png';
			case 'cs_1500_truck':
				return '/assets/USA/images/cs_1500_truck.png';
			case 'cs_1250_truck':
				return '/assets/USA/images/cs_1250_truck.png';
			case 'cs_1000_truck':
				return '/assets/USA/images/cs_1000_truck.png';
			case 'cs':
				return '/assets/USA/images/cs.png';
			case 'concrete_white':
				return '/assets/USA/images/concrete_white.png';
			case 'concrete_green':
				return '/assets/USA/images/concrete_green.png';
			case 'cold_storage_white':
				return '/assets/USA/images/cold_storage_white.png';
			case 'cold_storage_green':
				return '/assets/USA/images/cold_storage_green.png';
			case 'coil_handling_white':
				return '/assets/USA/images/coil_handling_white.png';
			case 'coil_handling_green':
				return '/assets/USA/images/coil_handling_green.png';
			case 'cb_truck':
				return '/assets/USA/images/cb_truck.png';
			case 'cb4_truck':
				return '/assets/USA/images/cb4_truck.png';
			case 'cb':
				return '/assets/USA/images/cb.png';
			case 'c_series_truck':
				return '/assets/USA/images/c_series_truck.png';
			case 'c_series':
				return '/assets/USA/images/c_series.png';
			case 'c9000_st_truck':
				return '/assets/USA/images/c9000_st_truck.png';
			case 'c5000_truck':
				return '/assets/USA/images/c5000_truck.png';
			case 'c10000_truck':
				return '/assets/USA/images/c10000_truck.png';
			case 'building_supplies_white':
				return '/assets/USA/images/building_supplies_white.png';
			case 'building_supplies_green':
				return '/assets/USA/images/building_supplies_green.png';
			case 'automotive_white':
				return '/assets/USA/images/automotive_white.png';
			case 'automotive_green':
				return '/assets/USA/images/automotive_green.png';
			case 'applications_white':
				return '/assets/USA/images/applications_white.png';
			case 'applications_green':
				return '/assets/USA/images/applications_green.png';
			case 'am':
				return '/assets/USA/images/am.png';
			case 'aluminium_white':
				return '/assets/USA/images/aluminium_white.png';
			case 'aluminium_green':
				return '/assets/USA/images/aluminium_green.png';
			case 'aisle_width_calculator_white':
				return '/assets/USA/images/aisle_width_calculator_white.png';
			case 'aisle_width_calculator_green':
				return '/assets/USA/images/aisle_width_calculator_green.png';
			case 'aisle_master_truck':
				return '/assets/USA/images/aisle_master_truck.png';
			case 'aisle_master':
				return '/assets/USA/images/aisle_master.png';
			case 'agriculture_white':
				return '/assets/USA/images/agriculture_white.png';
			case 'agriculture_green':
				return '/assets/USA/images/agriculture_green.png';
			case 'agricultural_white':
				return '/assets/USA/images/agricultural_white.png';
			case 'about_us_white':
				return '/assets/USA/images/about_us_white.png';
			case 'about_us_green':
				return '/assets/USA/images/about_us_green.png';
			case '4wsl_truck':
				return '/assets/USA/images/4wsl_truck.png';
			case 'am_15ne':
				return '/assets/USA/images/am_15ne.png';
			case 'am_15se':
				return '/assets/USA/images/am_15se.png';
			case 'am_20s':
				return '/assets/USA/images/am_20s.png';
			case 'am_20se':
				return '/assets/USA/images/am_20se.png';
			case 'am_20w':
				return '/assets/USA/images/am_20w.png';
			case 'am_20we':
				return '/assets/USA/images/am_20we.png';
			case 'am_25w':
				return '/assets/USA/images/am_25w.png';
			case 'am_25we':
				return '/assets/USA/images/am_25we.png';
			case 'am_30we':
				return '/assets/USA/images/am_30we.png';
			case 'am_33ne':
				return '/assets/USA/images/am_33ne.png';
			case 'am_33e':
				return '/assets/USA/images/am_33e.png';
			case 'am_44s':
				return '/assets/USA/images/am_44s.png';
			case 'am_44e':
				return '/assets/USA/images/am_44e.png';
			case 'am_44w':
				return '/assets/USA/images/am_44w.png';
			case 'am_55':
				return '/assets/USA/images/am_55.png';
			case 'am_55e':
				return '/assets/USA/images/am_55e.png';
			case 'am_60we':
				return '/assets/USA/images/am_60we.png';
			case 'c3000_st_truck':
				return '/assets/USA/images/c3000_st_truck.png';
			case 'c6000_sl_truck':
				return '/assets/USA/images/c6000_sl_truck.png';
			case 'c5000_sl_truck':
				return '/assets/USA/images/c5000_sl_truck.png';
			case 'c4000_ste_truck':
				return '/assets/USA/images/c4000_ste_truck.png';
			case 'c4000_st_truck':
				return '/assets/USA/images/c4000_st_truck.png';
			case 'c3000_ste_truck':
				return '/assets/USA/images/c3000_ste_truck.png';
			case 'c12000_sl_truck':
				return '/assets/USA/images/c12000_sl_truck.png';
			case 'c10000_sl_truck':
				return '/assets/USA/images/c10000_sl_truck.png';
			case 'c8000_ste_truck':
				return '/assets/USA/images/c8000_ste_truck.png';
			case 'c8000_st_truck':
				return '/assets/USA/images/c8000_st_truck.png';
			case 'c6000_ste_truck':
				return '/assets/USA/images/c6000_ste_truck.png';
			case 'c6000_st_truck':
				return '/assets/USA/images/c6000_st_truck.png';
			case 'c5000_ste_truck':
				return '/assets/USA/images/c5000_ste_truck.png';
			case 'c2500_ste_truck':
				return '/assets/USA/images/c2500_ste_truck.png';
			case 'c5000_st_truck':
				return '/assets/USA/images/c5000_st_truck.png';
			case 'c2500_st_truck':
				return '/assets/USA/images/c2500_st_truck.png';
			case 'sl_truck':
				return '/assets/USA/images/sl_truck.png';
			case 'attachments_green':
				return '/assets/USA/images/attachments_green.png';
			case 'attachments_white':
				return '/assets/USA/images/attachments_white.png';
			case 'salvage_green':
				return '/assets/USA/images/salvage_green.png';
			case 'salvage_white':
				return '/assets/USA/images/salvage_white.png';
			case 'guided_aisle_green':
				return '/assets/USA/images/guided_aisle_green.png';
			case 'guided_aisle_white':
				return '/assets/USA/images/guided_aisle_white.png';
			case 'spreader_bar_green':
				return '/assets/USA/images/spreader_bar_green.png';
			case 'spreader_bar_white':
				return '/assets/USA/images/spreader_bar_white.png';
			case 'hydraulic_forks_green':
				return '/assets/USA/images/hydraulic_forks_green.png';
			case 'hydraulic_forks_white':
				return '/assets/USA/images/hydraulic_forks_white.png';
			case 'pipe_clamp_green':
				return '/assets/USA/images/pipe_clamp_green.png';
			case 'pipe_clamp_white':
				return '/assets/USA/images/pipe_clamp_white.png';
			case 'telescopic_forks_green':
				return '/assets/USA/images/telescopic_forks_green.png';
			case 'telescopic_forks_white':
				return '/assets/USA/images/telescopic_forks_white.png';
			case 'builders_supplies_diy_green':
				return '/assets/USA/images/builders_supplies_diy_green.png';
			case 'builders_supplies_diy_white':
				return '/assets/USA/images/builders_supplies_diy_white.png';
			case 'straddle_carrier_attachments_white':
				return '/assets/USA/images/straddle_carrier_attachments_white.png';
			case 'straddle_carrier_attachments_green':
				return '/assets/USA/images/straddle_carrier_attachments_green.png';

			default:
                return '/assets/images/generic.png';

		}
	}

	static getLocalImageForUK(imageName) {
		switch (imageName) {
      case 'presentation_white':
        return '/assets/UK/images/presentation_white.png';
			case 'cbe_8000_truck':
				return '/assets/UK/images/cbe_8000_truck.png';
			case 'cbe_4000_truck':
				return '/assets/UK/images/cbe_4000_truck.png';
			case 'cb_6000_truck':
				return '/assets/UK/images/cb_6000_truck.png';
			case 'cb_5000_truck':
				return '/assets/UK/images/cb_5000_truck.png';
			case 'cb_3000_truck':
				return '/assets/UK/images/cb_3000_truck.png';
			case 'cb_2500_truck':
				return '/assets/UK/images/cb_2500_truck.png';
			case 'cbe_6000_truck':
				return '/assets/UK/images/cbe_6000_truck.png';
			case 'cbe_5000_truck':
				return '/assets/UK/images/cbe_5000_truck.png';
			case 'cbe_3000_truck':
				return '/assets/UK/images/cbe_3000_truck.png';
			case 'cbe_2500_truck':
				return '/assets/UK/images/cbe_2500_truck.png';
			case 'c9000_truck':
				return '/assets/UK/images/c9000_truck.png';
			case 'c8000_truck':
				return '/assets/UK/images/c8000_truck.png';
			case 'c7000_truck':
				return '/assets/UK/images/c7000_truck.png';
			case 'c6000_truck':
				return '/assets/UK/images/c6000_truck.png';
			case 'c6000_compact_truck':
				return '/assets/UK/images/c6000_compact_truck.png';
			case 'c5000_compact_truck':
				return '/assets/UK/images/c5000_compact_truck.png';
			case 'c4800_truck':
				return '/assets/UK/images/c4800_truck.png';
			case 'c4500_truck':
				return '/assets/UK/images/c4500_truck.png';
			case 'c4000_truck':
				return '/assets/UK/images/c4000_truck.png';
			case 'c3500_truck':
				return '/assets/UK/images/c3500_truck.png';
			case 'c3000_truck':
				return '/assets/UK/images/c3000_truck.png';
			case 'c2500_truck':
				return '/assets/UK/images/c2500_truck.png';
			case 'cb_8000_truck':
				return '/assets/UK/images/cb_8000_truck.png';
			case 'cb_4000_truck':
				return '/assets/UK/images/cb_4000_truck.png';
			case 'c6000_fwsl_truck':
				return '/assets/UK/images/c6000_fwsl_truck.png';
			case 'c5000_fwsl_truck':
				return '/assets/UK/images/c5000_fwsl_truck.png';
			case 'c12000_fwsl_truck':
				return '/assets/UK/images/c12000_fwsl_truck.png';
			case 'c10000_fwsl_truck':
				return '/assets/UK/images/c10000_fwsl_truck.png';
			case 'c6000_gte_truck':
				return '/assets/UK/images/c6000_gte_truck.png';
			case 'c6000_gt_truck':
				return '/assets/UK/images/c6000_gt_truck.png';
			case 'c4500_gte_truck':
				return '/assets/UK/images/c4500_gte_truck.png';
			case 'c4500_gt_truck':
				return '/assets/UK/images/c4500_gt_truck.png';
			case 'c3000_gte_truck':
				return '/assets/UK/images/c3000_gte_truck.png';
			case 'c10000_gte_truck':
				return '/assets/UK/images/c10000_gte_truck.png';
			case 'c10000_gt_truck':
				return '/assets/UK/images/c10000_gt_truck.png';
			case 'c7000et_truck':
				return '/assets/UK/images/c7000et_truck.png';
			case 'c6000et_truck':
				return '/assets/UK/images/c6000et_truck.png';
			case 'c3500et_truck':
				return '/assets/UK/images/c3500et_truck.png';
			case 'c3000et_truck':
				return '/assets/UK/images/c3000et_truck.png';
			case 'c9000et_truck':
				return '/assets/UK/images/c9000et_truck.png';
			case 'c8000et_truck':
				return '/assets/UK/images/c8000et_truck.png';
			case 'c4500et_truck':
				return '/assets/UK/images/c4500et_truck.png';
			case 'c4000et_truck':
				return '/assets/UK/images/c4000et_truck.png';
			case 'c5500et_truck':
				return '/assets/UK/images/c5500et_truck.png';
			case 'c5000et_truck':
				return '/assets/UK/images/c5000et_truck.png';
			case 'c12000et_truck':
				return '/assets/UK/images/c12000et_truck.png';
			case 'c11000et_truck':
				return '/assets/UK/images/c11000et_truck.png';
			case 'c10000et_truck':
				return '/assets/UK/images/c10000et_truck.png';
			case 'c5500xl_truck':
				return '/assets/UK/images/c5500xl_truck.png';
			case 'c5000xl_truck':
				return '/assets/UK/images/c5000xl_truck.png';
			case 'c12000xl_truck':
				return '/assets/UK/images/c12000xl_truck.png';
			case 'c1000_truck':
				return '/assets/UK/images/c1000_truck.png';
			case 'c10000xl_truck':
				return '/assets/UK/images/c10000xl_truck.png';
			case 'c22000_truck':
				return '/assets/UK/images/c22000_truck.png';
			case 'c26000h_truck':
				return '/assets/UK/images/c26000h_truck.png';
			case 'c26000_truck':
				return '/assets/UK/images/c26000_truck.png';
			case 'c12000h_truck':
				return '/assets/UK/images/c12000h_truck.png';
			case 'c12000_truck':
				return '/assets/UK/images/c12000_truck.png';
			case 'c35000_truck':
				return '/assets/UK/images/c35000_truck.png';
			case 'c30000_truck':
				return '/assets/UK/images/c30000_truck.png';
			case 'c16000_truck':
				return '/assets/UK/images/c16000_truck.png';
			case 'c55000_truck':
				return '/assets/UK/images/c55000_truck.png';
			case 'c50000_truck':
				return '/assets/UK/images/c50000_truck.png';
			case 'c25000_truck':
				return '/assets/UK/images/c25000_truck.png';
			case 'c20000_truck':
				return '/assets/UK/images/c20000_truck.png';
			case 'c17300_truck':
				return '/assets/UK/images/c17300_truck.png';
			case 'c14000_truck':
				return '/assets/UK/images/c14000_truck.png';
			case 'engineering_wood_white':
				return '/assets/UK/images/engineering_wood_white.png';
			case 'wr_truck':
				return '/assets/UK/images/wr_truck.png';
			case 'wr4_truck':
				return '/assets/UK/images/wr4_truck.png';
			case 'wr4':
				return '/assets/UK/images/wr4.png';
			case 'wr':
				return '/assets/UK/images/wr.png';
			case 'woodwork_millwork_white':
				return '/assets/UK/images/woodwork_millwork_white.png';
			case 'woodwork_millwork_green':
				return '/assets/UK/images/woodwork_millwork_green.png';
			case 'warehousing_white':
				return '/assets/UK/images/warehousing_white.png';
			case 'warehousing_green':
				return '/assets/UK/images/warehousing_green.png';
			case 'warehouse_white':
				return '/assets/UK/images/warehouse_white.png';
			case 'warehouse_design_white':
				return '/assets/UK/images/warehouse_design_white.png';
			case 'warehouse_design_green':
				return '/assets/UK/images/warehouse_design_green.png';
			case 'wall_panels_timberframe_white':
				return '/assets/UK/images/wall_panels_timberframe_white.png';
			case 'wall_panels_timberframe_green':
				return '/assets/UK/images/wall_panels_timberframe_green.png';
			case 'vinyl_siding_roofing_white':
				return '/assets/UK/images/vinyl_siding_roofing_white.png';
			case 'vinyl_siding_roofing_green':
				return '/assets/UK/images/vinyl_siding_roofing_green.png';
			case 'video':
				return '/assets/UK/images/video.png';
			case 'trusses_white':
				return '/assets/UK/images/trusses_white.png';
			case 'trusses_green':
				return '/assets/UK/images/trusses_green.png';
			case 'trucks_white':
				return '/assets/UK/images/trucks_white.png';
			case 'trucks_green':
				return '/assets/UK/images/trucks_green.png';
			case 'timeline_history_white':
				return '/assets/UK/images/timeline_history_white.png';
			case 'timberframe_green':
				return '/assets/UK/images/timberframe_green.png';
			case 'testimonials_white':
				return '/assets/UK/images/testimonials_white.png';
			case 'testimonials_green':
				return '/assets/UK/images/testimonials_green.png';
			case 'testimonial_green':
				return '/assets/UK/images/testimonial_green.png';
			case 'technical_support_white':
				return '/assets/UK/images/technical_support_white.png';
			case 'technical_support_green':
				return '/assets/UK/images/technical_support_green.png';
			case 'straddle_carrier':
				return '/assets/UK/images/straddle_carrier.png';
			case 'storage_white':
				return '/assets/UK/images/storage_white.png';
			case 'storage_green':
				return '/assets/UK/images/storage_green.png';
			case 'steel_service_center_stockholders_white':
				return '/assets/UK/images/steel_service_center_stockholders_white.png';
			case 'steel_service_center_stockholders_green':
				return '/assets/UK/images/steel_service_center_stockholders_green.png';
			case 'steel_pipe_white':
				return '/assets/UK/images/steel_pipe_white.png';
			case 'steel_pipe_green':
				return '/assets/UK/images/steel_pipe_green.png';
			case 'steel_aluminium_white':
				return '/assets/UK/images/steel_aluminium_white.png';
			case 'steel_aluminium_green':
				return '/assets/UK/images/steel_aluminium_green.png';
			case 'stand_up_truck':
				return '/assets/UK/images/stand_up_truck.png';
			case 'st_truck':
				return '/assets/UK/images/st_truck.png';
			case 'st':
				return '/assets/UK/images/st.png';
			case 'speciality_metals_stainless_steel_white':
				return '/assets/UK/images/speciality_metals_stainless_steel_white.png';
			case 'speciality_metals_stainless_steel_green':
				return '/assets/UK/images/speciality_metals_stainless_steel_green.png';
			case 'sl':
				return '/assets/UK/images/sl.png';
			case 'site_survey_white':
				return '/assets/UK/images/site_survey_white.png';
			case 'site_survey_green':
				return '/assets/UK/images/site_survey_green.png';
			case 'sc_truck':
				return '/assets/UK/images/sc_truck.png';
			case 'sc':
				return '/assets/UK/images/sc.png';
			case 'sales_team_white':
				return '/assets/UK/images/sales_team_white.png';
			case 'sales_team_green':
				return '/assets/UK/images/sales_team_green.png';
			case 'safety_white':
				return '/assets/UK/images/safety_white.png';
			case 'safety_storage_efficiency_yellow':
				return '/assets/UK/images/safety_storage_efficiency_yellow.png';
			case 'safety_storage_efficiency_white':
				return '/assets/UK/images/safety_storage_efficiency_white.png';
			case 'safety_storage_efficiency_green':
				return '/assets/UK/images/safety_storage_efficiency_green.png';
			case 'safety_green':
				return '/assets/UK/images/safety_green.png';
			case 'rt_truck':
				return '/assets/UK/images/rt_truck.png';
			case 'rt':
				return '/assets/UK/images/rt.png';
			case 'rough_terrain_white':
				return '/assets/UK/images/rough_terrain_white.png';
			case 'rough_terrain_green':
				return '/assets/UK/images/rough_terrain_green.png';
			case 'roll_forming_white':
				return '/assets/UK/images/roll_forming_white.png';
			case 'roll_forming_green':
				return '/assets/UK/images/roll_forming_green.png';
			case 'renewables_white':
				return '/assets/UK/images/renewables_white.png';
			case 'renewables_green':
				return '/assets/UK/images/renewables_green.png';
			case 'rebar_white':
				return '/assets/UK/images/rebar_white.png';
			case 'rebar_green':
				return '/assets/UK/images/rebar_green.png';
			case 'precast_white':
				return '/assets/UK/images/precast_white.png';
			case 'precast_green':
				return '/assets/UK/images/precast_green.png';
			case 'ppt_truck':
				return '/assets/UK/images/ppt_truck.png';
			case 'ppt':
				return '/assets/UK/images/ppt.png';
			case 'play_white':
				return '/assets/UK/images/play_white.png';
			case 'play_green':
				return '/assets/UK/images/play_green.png';
			case 'pedestrian_truck':
				return '/assets/UK/images/pedestrian_truck.png';
			case 'operator_training_white':
				return '/assets/UK/images/operator_training_white.png';
			case 'op_truck':
				return '/assets/UK/images/op_truck.png';
			case 'op':
				return '/assets/UK/images/op.png';
			case 'oil_gas_white':
				return '/assets/UK/images/oil_gas_white.png';
			case 'oil_gas_energy_white':
				return '/assets/UK/images/oil_gas_energy_white.png';
			case 'oil_gas_energy_green':
				return '/assets/UK/images/oil_gas_energy_green.png';
			case 'north_american_team_white':
				return '/assets/UK/images/north_american_team_white.png';
			case 'north_american_team_green':
				return '/assets/UK/images/north_american_team_green.png';
			case 'north_american_contacts_white':
				return '/assets/UK/images/north_american_contacts_white.png';
			case 'north_american_contacts_green':
				return '/assets/UK/images/north_american_contacts_green.png';
			case 'multi_directional_truck':
				return '/assets/UK/images/multi_directional_truck.png';
			case 'mr_truck':
				return '/assets/UK/images/mr_truck.png';
			case 'mr':
				return '/assets/UK/images/mr.png';
			case 'mg_truck':
				return '/assets/UK/images/mg_truck.png';
			case 'mg':
				return '/assets/UK/images/mg.png';
			case 'manufacturing_white':
				return '/assets/UK/images/manufacturing_white.png';
			case 'manufacturing_green':
				return '/assets/UK/images/manufacturing_green.png';
			case 'lumber_white':
				return '/assets/UK/images/lumber_white.png';
			case 'lumber_green':
				return '/assets/UK/images/lumber_green.png';
			case 'lumbar_white':
				return '/assets/UK/images/lumbar_white.png';
			case 'lr_truck':
				return '/assets/UK/images/lr_truck.png';
			case 'key_message_white':
				return '/assets/UK/images/key_message_white.png';
			case 'key_facts_white':
				return '/assets/UK/images/key_facts_white.png';
			case 'hq_contacts_white':
				return '/assets/UK/images/hq_contacts_white.png';
			case 'hq_contacts_green':
				return '/assets/UK/images/hq_contacts_green.png';
			case 'hollowcore_white':
				return '/assets/UK/images/hollowcore_white.png';
			case 'hollowcore_green':
				return '/assets/UK/images/hollowcore_green.png';
			case 'heavy_manufacturing_white':
				return '/assets/UK/images/heavy_manufacturing_white.png';
			case 'heavy_manufacturing_green':
				return '/assets/UK/images/heavy_manufacturing_green.png';
			case 'heat_treatment_white':
				return '/assets/UK/images/heat_treatment_white.png';
			case 'heat_treatment_green':
				return '/assets/UK/images/heat_treatment_green.png';
			case 'gte_6000_truck':
				return '/assets/UK/images/gte_6000_truck.png';
			case 'gt_truck':
				return '/assets/UK/images/gt_truck.png';
			case 'gt_6000_truck':
				return '/assets/UK/images/gt_6000_truck.png';
			case 'gt_10000_truck':
				return '/assets/UK/images/gt_10000_truck.png';
			case 'gt':
				return '/assets/UK/images/gt.png';
			case 'glavanising_white':
				return '/assets/UK/images/glavanising_white.png';
			case 'galvanising_white':
				return '/assets/UK/images/galvanising_white.png';
			case 'galvanising_green':
				return '/assets/UK/images/galvanising_green.png';
			case 'food_and_drink_white':
				return '/assets/UK/images/food_and_drink_white.png';
			case 'food_and_drink_green':
				return '/assets/UK/images/food_and_drink_green.png';
			case 'fabrication_white':
				return '/assets/UK/images/fabrication_white.png';
			case 'fabrication_green':
				return '/assets/UK/images/fabrication_green.png';
			case 'esl_truck':
				return '/assets/UK/images/esl_truck.png';
			case 'engineered_wood_white':
				return '/assets/UK/images/engineered_wood_white.png';
			case 'engineered_wood_green':
				return '/assets/UK/images/engineered_wood_green.png';
			case 'efficiencies_white':
				return '/assets/UK/images/efficiencies_white.png';
			case 'efficiencies_green':
				return '/assets/UK/images/efficiencies_green.png';
			case 'distribution_white':
				return '/assets/UK/images/distribution_white.png';
			case 'distribution_green':
				return '/assets/UK/images/distribution_green.png';
			case 'cs_truck':
				return '/assets/UK/images/cs_truck.png';
			case 'cs_850_truck':
				return '/assets/UK/images/cs_850_truck.png';
			case 'cs_4400_truck':
				return '/assets/UK/images/cs_4400_truck.png';
			case 'cs_3850_truck':
				return '/assets/UK/images/cs_3850_truck.png';
			case 'cs_3200_truck':
				return '/assets/UK/images/cs_3200_truck.png';
			case 'cs_2750_truck':
				return '/assets/UK/images/cs_2750_truck.png';
			case 'cs_2200_truck':
				return '/assets/UK/images/cs_2200_truck.png';
			case 'cs_2000_truck':
				return '/assets/UK/images/cs_2000_truck.png';
			case 'cs_1870_truck':
				return '/assets/UK/images/cs_1870_truck.png';
			case 'cs_1750_truck':
				return '/assets/UK/images/cs_1750_truck.png';
			case 'cs_1500_truck':
				return '/assets/UK/images/cs_1500_truck.png';
			case 'cs_1250_truck':
				return '/assets/UK/images/cs_1250_truck.png';
			case 'cs_1000_truck':
				return '/assets/UK/images/cs_1000_truck.png';
			case 'cs':
				return '/assets/UK/images/cs.png';
			case 'concrete_white':
				return '/assets/UK/images/concrete_white.png';
			case 'concrete_green':
				return '/assets/UK/images/concrete_green.png';
			case 'cold_storage_white':
				return '/assets/UK/images/cold_storage_white.png';
			case 'cold_storage_green':
				return '/assets/UK/images/cold_storage_green.png';
			case 'coil_handling_white':
				return '/assets/UK/images/coil_handling_white.png';
			case 'coil_handling_green':
				return '/assets/UK/images/coil_handling_green.png';
			case 'cb_truck':
				return '/assets/UK/images/cb_truck.png';
			case 'cb4_truck':
				return '/assets/UK/images/cb4_truck.png';
			case 'cb':
				return '/assets/UK/images/cb.png';
			case 'c_series_truck':
				return '/assets/UK/images/c_series_truck.png';
			case 'c_series':
				return '/assets/UK/images/c_series.png';
			case 'c9000_st_truck':
				return '/assets/UK/images/c9000_st_truck.png';
			case 'c5000_truck':
				return '/assets/UK/images/c5000_truck.png';
			case 'c10000_truck':
				return '/assets/UK/images/c10000_truck.png';
			case 'building_supplies_white':
				return '/assets/UK/images/building_supplies_white.png';
			case 'building_supplies_green':
				return '/assets/UK/images/building_supplies_green.png';
			case 'automotive_white':
				return '/assets/UK/images/automotive_white.png';
			case 'automotive_green':
				return '/assets/UK/images/automotive_green.png';
			case 'applications_white':
				return '/assets/UK/images/applications_white.png';
			case 'applications_green':
				return '/assets/UK/images/applications_green.png';
			case 'am':
				return '/assets/UK/images/am.png';
			case 'aluminium_white':
				return '/assets/UK/images/aluminium_white.png';
			case 'aluminium_green':
				return '/assets/UK/images/aluminium_green.png';
			case 'aisle_width_calculator_white':
				return '/assets/UK/images/aisle_width_calculator_white.png';
			case 'aisle_width_calculator_green':
				return '/assets/UK/images/aisle_width_calculator_green.png';
			case 'aisle_master_truck':
				return '/assets/UK/images/aisle_master_truck.png';
			case 'aisle_master':
				return '/assets/UK/images/aisle_master.png';
			case 'agriculture_white':
				return '/assets/UK/images/agriculture_white.png';
			case 'agriculture_green':
				return '/assets/UK/images/agriculture_green.png';
			case 'agricultural_white':
				return '/assets/UK/images/agricultural_white.png';
			case 'about_us_white':
				return '/assets/UK/images/about_us_white.png';
			case 'about_us_green':
				return '/assets/UK/images/about_us_green.png';
			case '4wsl_truck':
				return '/assets/UK/images/4wsl_truck.png';
			case 'am_15ne':
				return '/assets/UK/images/am_15ne.png';
			case 'am_15se':
				return '/assets/UK/images/am_15se.png';
			case 'am_20s':
				return '/assets/UK/images/am_20s.png';
			case 'am_20se':
				return '/assets/UK/images/am_20se.png';
			case 'am_20w':
				return '/assets/UK/images/am_20w.png';
			case 'am_20we':
				return '/assets/UK/images/am_20we.png';
			case 'am_25w':
				return '/assets/UK/images/am_25w.png';
			case 'am_25we':
				return '/assets/UK/images/am_25we.png';
			case 'am_30we':
				return '/assets/UK/images/am_30we.png';
			case 'am_33ne':
				return '/assets/UK/images/am_33ne.png';
			case 'am_33e':
				return '/assets/UK/images/am_33e.png';
			case 'am_44s':
				return '/assets/UK/images/am_44s.png';
			case 'am_44e':
				return '/assets/UK/images/am_44e.png';
			case 'am_44w':
				return '/assets/UK/images/am_44w.png';
			case 'am_55':
				return '/assets/UK/images/am_55.png';
			case 'am_55e':
				return '/assets/UK/images/am_55e.png';
			case 'am_60we':
				return '/assets/UK/images/am_60we.png';
			case 'c3000_st_truck':
				return '/assets/UK/images/c3000_st_truck.png';
			case 'c6000_sl_truck':
				return '/assets/UK/images/c6000_sl_truck.png';
			case 'c5000_sl_truck':
				return '/assets/UK/images/c5000_sl_truck.png';
			case 'c4000_ste_truck':
				return '/assets/UK/images/c4000_ste_truck.png';
			case 'c4000_st_truck':
				return '/assets/UK/images/c4000_st_truck.png';
			case 'c3000_ste_truck':
				return '/assets/UK/images/c3000_ste_truck.png';
			case 'c12000_sl_truck':
				return '/assets/UK/images/c12000_sl_truck.png';
			case 'c10000_sl_truck':
				return '/assets/UK/images/c10000_sl_truck.png';
			case 'c8000_ste_truck':
				return '/assets/UK/images/c8000_ste_truck.png';
			case 'c8000_st_truck':
				return '/assets/UK/images/c8000_st_truck.png';
			case 'c6000_ste_truck':
				return '/assets/UK/images/c6000_ste_truck.png';
			case 'c6000_st_truck':
				return '/assets/UK/images/c6000_st_truck.png';
			case 'c5000_ste_truck':
				return '/assets/UK/images/c5000_ste_truck.png';
			case 'c2500_ste_truck':
				return '/assets/UK/images/c2500_ste_truck.png';
			case 'c5000_st_truck':
				return '/assets/UK/images/c5000_st_truck.png';
			case 'c2500_st_truck':
				return '/assets/UK/images/c2500_st_truck.png';
			case 'sl_truck':
				return '/assets/UK/images/sl_truck.png';
			case 'attachments_green':
				return '/assets/UK/images/attachments_green.png';
			case 'attachments_white':
				return '/assets/UK/images/attachments_white.png';
			case 'salvage_green':
				return '/assets/UK/images/salvage_green.png';
			case 'salvage_white':
				return '/assets/UK/images/salvage_white.png';
			case 'guided_aisle_green':
				return '/assets/UK/images/guided_aisle_green.png';
			case 'guided_aisle_white':
				return '/assets/UK/images/guided_aisle_white.png';
			case 'spreader_bar_green':
				return '/assets/UK/images/spreader_bar_green.png';
			case 'spreader_bar_white':
				return '/assets/UK/images/spreader_bar_white.png';
			case 'hydraulic_forks_green':
				return '/assets/UK/images/hydraulic_forks_green.png';
			case 'hydraulic_forks_white':
				return '/assets/UK/images/hydraulic_forks_white.png';
			case 'pipe_clamp_green':
				return '/assets/UK/images/pipe_clamp_green.png';
			case 'pipe_clamp_white':
				return '/assets/UK/images/pipe_clamp_white.png';
			case 'telescopic_forks_green':
				return '/assets/UK/images/telescopic_forks_green.png';
			case 'telescopic_forks_white':
				return '/assets/UK/images/telescopic_forks_white.png';
			case 'builders_supplies_diy_green':
				return '/assets/UK/images/builders_supplies_diy_green.png';
			case 'builders_supplies_diy_white':
				return '/assets/UK/images/builders_supplies_diy_white.png';
			case 'straddle_carrier_attachments_white':
				return '/assets/UK/images/straddle_carrier_attachments_white.png';
			case 'straddle_carrier_attachments_green':
				return '/assets/UK/images/straddle_carrier_attachments_green.png';

			default:
				return '/assets/images/generic.png';

		}
	}
}

export default StaticImageResource