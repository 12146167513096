import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

const Footer = () => {
    
    return (
        <footer className="footer-container">
            <div className="wrap">
                <div className="clearFloat">
                    <div className="left">
                        <div className="copyright">
                            &copy; 2018 CombiLift. All Rights Reserved.
                        </div>
                    </div>
                    <div className="right">

                    </div>
                </div>
                
            </div>
        </footer>
    );  
};

export default Footer;