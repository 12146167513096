import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, FieldArray } from "redux-form";
import ArrayInputComponent from "./ArrayInputComponent";
import TextInput from "./TextInput";
import { initialize } from "redux-form";
import _ from "lodash";
import {Gen} from "../helpers/gen";
import constants from "../constants";
const sharableContentHeadings = ["Capacity Guidelines", "Aisle Drawing","Battery Drawings","Technical Specification Sheet", "Technical Documents", "Brochure"];

class EditTemplateComponent extends React.Component {
  componentDidMount() {
    this.initializeForm(this.props.data);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data && !_.isEqual(nextProps.data, this.props.data))
      this.initializeForm(nextProps.data);
  }

  initializeForm = data => {
    this.initialValues = {};
      if(data.Video) {
          this.initialValues['Video'] = data.Video.videos[0].url;
      }
    if(data.pdf) {
      this.initialValues['pdf'] = data.pdf;
    }
    if(data.contentText) {
      this.initialValues['contentText'] = data.contentText;
    }
    if (data.featureImages)
      this.initialValues["featureImages"] = Object.values(data.featureImages).map(
        eachField => eachField.url
      );
    if (data.featuresText)
      this.initialValues["featuresText"] = Object.values(data.featuresText);
    if (data.videos)
      this.initialValues["videos"] = Object.values(data.videos).map(
        eachField => eachField.url
      );
      this.initialValues.sharableContent = {};
      if(data.sharableContent) {
        if (Gen.isDoublyNested(data.path))
        {
            Object.keys(data.sharableContent).map((eachCategory) => {
                this.initialValues.sharableContent[eachCategory] = {};
                this.initializeSharableContent(data.sharableContent[eachCategory],this.initialValues.sharableContent[eachCategory]);
            })
            }
        else
                this.initializeSharableContent(data.sharableContent,this.initialValues.sharableContent);

    }
    this.props.dispatch(initialize("editTemplateForm", this.initialValues));
  };

    initializeSharableContent = (sharableContent, initialValue) => {
        Object.keys(sharableContent).map((eachCategory) => {
                initialValue[eachCategory] = {};
                if (sharableContent[eachCategory].images) {
                    let images = [];
                    Gen.getArrayFromObject(sharableContent[eachCategory].images).map(
                        eachField => images.push({url:eachField.url,name: eachField.name})
                    );
                    initialValue[eachCategory].images = images;

                }
                if (sharableContent[eachCategory].pdfs) {
                    let pdfs = [];
                    Gen.getArrayFromObject(sharableContent[eachCategory].pdfs).map(
                        eachField => pdfs.push({url:eachField.url,name: eachField.name})
                    );
                    initialValue[eachCategory].pdfs = pdfs;
                }
            }
        )
    }

    renderSharableContentFields= (namePrefix,sharableContentHeadings)=> {
      return sharableContentHeadings.map((eachCategory, index) =>
            <div key={index} className={"sharableContent"}>
                <FieldArray
                    multipleFields={true}
                    component={ArrayInputComponent}
                    type={"textarea"}
                    label={eachCategory + ' Images'}
                    name={`${namePrefix}[${eachCategory}].images`}
                />
                <br/>
                <FieldArray
                    multipleFields={true}
                    component={ArrayInputComponent}
                    type={"textarea"}
                    label={eachCategory + " Pdfs"}
                    name={`${namePrefix}[${eachCategory}].pdfs`}
                />
            </div>
        )
    };

    deleteVideoField = (formValues) => {
        formValues.Video = "";
        this.props.updateTemplateView(formValues)
    };

    submitForm = (formValues) => {

        if(this.props.placeHolder.Video ){
            if(!formValues.Video || !Gen.isValidUrl(Gen.encodeUrl(formValues.Video))){
                alert('enter a valid video url');
                return
            }
        }
        if(this.props.placeHolder.pdf ){
         if(!formValues.pdf || !Gen.isValidUrl(Gen.encodeUrl(formValues.pdf))){
          alert('enter a valid pdf url');
          return
         }
        }
        this.props.updateTemplateView(formValues)
    };


   getHeadingData = (path) => {
       return (Gen.getArrayFromObject(path)[0] === 'USA') ?  constants.SHARABLE_TRUCK_HEADINGS_IMPERIAL: constants.SHARABLE_TRUCK_HEADINGS_METRIC;
   };

  render() {
    const { data, handleSubmit, updateTemplateView, placeHolder } = this.props;
    return (
      <div className={"edit-content-wrapper"}>
        {placeHolder.contentText && (
          <Field
            name="contentText"
            component={TextInput}
            type="textarea"
            label={"Edit Content Text"}
          />
        )}
        <br />
          {placeHolder.featuresText && (<FieldArray
            name="featuresText"
            component={ArrayInputComponent}
            label={"Features Text"}
          />
          )}
        <br />
          {placeHolder.featuresText && (
          <FieldArray
            component={ArrayInputComponent}
            label={"Feature Images"}
            name="featureImages"
            type="textarea"
          />
          )}
        <br />
        {placeHolder.videos && (
          <FieldArray
            component={ArrayInputComponent}
            type={"textarea"}
            label={"Video Url"}
            name="videos"
          />
        )}
          {
              placeHolder.sharableContent && (
                  <React.Fragment>
                      {Gen.isDoublyNested(data.path) ?
                          Object.keys(this.getHeadingData(data.path)).map((eachCategory, key) => (
                             <div key={key} className={"nestedSharableContent"}>
                              <h3> {eachCategory} </h3>
                                 {this.renderSharableContentFields(`sharableContent[${eachCategory}]`,this.getHeadingData(data.path)[eachCategory])}
                             </div>
                                 )
                          )
                          :
                          this.renderSharableContentFields("sharableContent",sharableContentHeadings)
                      }
                  </React.Fragment>
              )
          }
          {placeHolder.Video && (
              <div className={'input-flex'}>
                  <div style={{flex: 0.9}}>
                      <Field
                          name="Video"
                          component={TextInput}
                          type="textarea"
                          label={this.initialValues && this.initialValues['Video'] ? "Edit Video Url" : "Add Video Url"}
                      />
                  </div>
                  {this.initialValues && this.initialValues['Video'] && <i
                      onClick={handleSubmit(this.deleteVideoField)}
                      className="fas fa-trash-alt"
                  />
                  }
              </div>
          )}
          {placeHolder.pdf && (
            <Field
              name="pdf"
              component={TextInput}
              type="textarea"
              label={"Edit Pdf Url"}
            />
          )
          }
          <br/>
        <br />
        <button
          className={"btn btn-primary btn-lg"}
          onClick={handleSubmit(this.submitForm)}
        >
            {placeHolder.Video ?  "ADD VIDEO" : "SUBMIT"}
        </button>
        <br />
        <br />
      </div>
    );
  }
}
EditTemplateComponent = reduxForm({
  form: "editTemplateForm"
})(EditTemplateComponent);

export default EditTemplateComponent;

EditTemplateComponent.propTypes = {
  data: PropTypes.object.isRequired,
  updateTemplateView: PropTypes.func.isRequired
};
