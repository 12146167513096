import firebase from "firebase";
import Constants from "../constants";
import axios from "axios";
import { notify } from "react-notify-toast";
import StaticImageResource from "./StaticImageResource";
import _ from 'lodash';
// User 1: Full Access
// Everything
//
// User 2: Combilift Dealer Specialist
// A: In North American team only, Sales Team Contact info
// B: In North American team only, Sales Team Contact info and remove all Aisle Master Info throughout app.
//
//
//   User 3: Dealer
// A: In North American team only, Sales Team Contact info & No Aisle Drawings or capacity guidelines
// B: In North American team only, Sales Team Contact info, No Aisle Drawings or capacity guidelines and remove all Aisle Master Info throughout app.
//
//
//   User 4: Internal Staff
// Remove Warehouse design and technical Suppor

export class Gen {
  static objectCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static round(num) {
    return Math.round(num);
  }

  static isUserAdmin(user) {
    return user && user.role && user.role === "admin";
  }

  static initAxios() {
    const axiosInstance = axios.create();
    this.axiosInstance = axiosInstance;
  }

  static isProduction() {
    const isProduction = process.env.NODE_ENV === "production";
    return isProduction;
  }

  static initializeFirebase() {
    const config = {
      apiKey: "AIzaSyA-U9-CAvDXyUdaYYd9C7yPVNEEn5UtSOY",
      authDomain: "combilift-1.firebaseapp.com",
      databaseURL: this.isProduction()
        ? "https://combilift-1.firebaseio.com"
        : "https://combilift-2.firebaseio.com",
      projectId: "combilift-1",
      storageBucket: "combilift-1.appspot.com",
      messagingSenderId: "1081958166723"
    };

    !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
  }

  static getIcon(path, icon) {
    if (path && path[0] === "USA")
      return StaticImageResource.getLocalImageForUSA(icon);
    else return StaticImageResource.getLocalImageForUK(icon);
  }

  static getPathFromPathArray(path) {
    let pathRef = path.reduce((total, current) => {
      return `${total}/${current}`;
    }, "");

    return "/navigation" + pathRef;
  }

  // path is an array
  static getContentForPath(path) {
    const database = this.getDatabase();
    let pathRef = this.getPathFromPathArray(path);

    return new Promise((resolve, reject) => {
      database.ref(pathRef).once(
        "value",
        snapshot => {
          let snapshotValue = snapshot.val();
          snapshotValue = this.reverseTransform(snapshotValue);
          resolve(snapshotValue);
        },
        errorObject => {
          reject(errorObject);
        }
      );
    });
  }

  static getPathOnItemClick(path, title) {
    path.push(btoa(title));
    return path;
  }

  static getPathOnBackPress(path) {
    path.pop();
    return path;
  }

  // Call this function when you want to update the data and pass the entire JSON (modified json of getContentForPath)
  static updateDataOnPath(path, data) {
    const pathRef = this.getPathFromPathArray(path);
    const database = this.getDatabase();

    const transformedData = this.transform(data);

    return database.ref(pathRef).set(transformedData);
  }

  // this function converts the keys to base16 keys in nested way.
  // Use this before uploading USA data to firebase, since firebase doesn't accept special characters as keys
  static transform(data) {
    if (typeof data !== "object") {
      return data;
    }
    const newData = {};

    for (let key of Object.keys(data)) {
      let value = data[key];
      let newValue = this.transform(value);
      let newKey = btoa(key);
      newData[newKey] = newValue;
    }
    return newData;
  }

  // this function converts the keys from base16 keys to ascii in nested way.
  // Use this to transform data uploaded to firebase
  static reverseTransform(data) {
    if (typeof data !== "object") {
      return data;
    }
    const newData = {};

    for (let key of Object.keys(data)) {
      let value = data[key];
      let newValue = this.reverseTransform(value);
      let newKey = atob(key);
      newData[newKey] = newValue;
    }
    return newData;
  }

  static validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static alert(data) {
    alert(JSON.stringify(data));
  }

  static sendNotification(title, body, userRoleToSendTheNotificationTo) {
    Gen.getDatabase()
      .ref(`${Constants.USERS}`)
      .once("value")
      .then(function(snapshot) {
        const snapShotValues = snapshot.val();
        for (let key of Object.keys(snapShotValues)) {
          const { status, expoToken, fcmToken, userRole } = snapShotValues[key];
          if (fcmToken && status!== Constants.DELETED &&  (userRoleToSendTheNotificationTo === Constants.USER_ROLE.EVERYONE || userRoleToSendTheNotificationTo === userRole) ) {

            // TODO: check if we need to add a base url as well.
            Gen.axiosInstance
              .post( "/sendnotification",
                {
                  to: expoToken,
                  title: title,
                  body: body,
                  data: {
                    title,
                    body
                  }
                },
                {}
              )
              .then(success => {
                console.log("sent notification");
              })
              .catch(error => {
                console.log(error);
                notify.show("Some error occurred", "error");
              });
          }
        }
      });
  }

  static getDatabase() {
    return firebase.database();
  }

  // returns true or false
  static isUserLoggedIn() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
    });
  }

  static isUserRealorOrAdmin(user) {
    return (
      user && user.role && (user.role === "realtor" || user.role === "admin")
    );
  }

  static baseName(str) {
    var base = new String(str).substring(str.lastIndexOf("/") + 1);
    if (base.lastIndexOf(".") != -1)
      base = base.substring(0, base.lastIndexOf("."));
    return base;
  }

  static mergeArray(a1, a2) {
    const newArray = Gen.objectCopy(a1);
    a2.map(item => {
      if (newArray.indexOf(item) < 0) {
        newArray.push(item);
      }
    });
    return newArray;
  }

  static getFormattedDate(date) {
    const year = date.split("-")[0];
    const month = Gen.getMonthFromNumber(date.split("-")[1]);

    return month + " " + year;
  }

  static getAvailableString(d1) {
    // d2 current date
    // d1 apartment date

    const d2 = Date.now();
    const d1parsed = Date.parse(d1);
    const diff = d1parsed - d2;
    if (diff < 0) {
      return "available";
    } else {
      const month = d1.split("-")[1];
      return "available from " + Gen.getMonthFromNumber(month);
    }
  }

  static getMonthFromNumber(month) {
    switch (month) {
      case "01":
        return "Jan";
      case "02":
        return "Feb";
      case "03":
        return "Mar";
      case "04":
        return "Apr";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "Aug";
      case "09":
        return "Sep";
      case "10":
        return "Oct";
      case "11":
        return "Nov";
      case "12":
        return "Dec";
      default:
        return "-";
    }
  }

  static getDefaultNameForVideoOrImage(arr) {
    let arrOfObjs = [];
    arr.forEach(item => arrOfObjs.push(item.url ? item : { name: "default_name",url: item }));
    return arrOfObjs;
  }

  static initializeLoaderRef(ref) {
    Gen.loaderRef = ref;
  }

  static showLoader() {
    Gen.loaderRef.show();
  }

  static hideLoader() {
    Gen.loaderRef.hide();
  }
    // needed since tranform function hasn't handled array as a separate case. And now we have done many changes with Object.values so keeping it as it is for now.
    static getArrayFromObject(obj) {
        if(obj) {
            const arr = [];
            for (let i = 0; i < Object.values(obj).length; i++){
                let key = i+"";
                let value = obj[key];
                arr.push(value);
            }
            return arr
        } else {
            return [];
        }
    }


    static isDoublyNested(path) {
     if (_.isEqual(this.getArrayFromObject(path),["USA","Trucks","Aisle Master","Electric"]) || _.isEqual(this.getArrayFromObject(path),["UK","Trucks","Aisle Master","Electric"]))
       return true;
     return false;

    }

    static isEncoded(uri) {
        uri = uri || '';
        return uri !== decodeURIComponent(uri);
    }

    static encodeUrl(url) {
        if (this.isEncoded(url))
            return url;
        else return encodeURI(url)
    }

    static isValidUrl(url) {
        let re = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return re.test(url);
    }

}
