import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {SLIDE_UP, XL} from 'react-ladda';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InternalTextBanner from './../components/internalTextBanner';
import Constants from '../constants';
import {Gen} from "../helpers/gen";
import EmailComponent from "../components/emailComponent";
import firebase from 'firebase';
import {notify} from "react-notify-toast";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emails: []
        }
    }

    head() {
        return (
            <Helmet>
                <title>{`Manage Users`}</title>
            </Helmet>
        );
    }

    getEmails() {
        Gen.getDatabase().ref(Constants.USERS).on('value', (snapshot) => {
            let emails = [];
            const snapShotValue = snapshot.val();

            for (let key of Object.keys(snapShotValue)) {
                let value = snapShotValue[key];
                const {status} = value;
                // console.log(key)

                if (status !== Constants.DELETED) {
                    let email = atob(key);
                    emails.push({
                        email, ...value
                    });
                }
            }
            this.setState({
                emails: emails,
                showAddEmailInput: false
            })
        })
    }

    componentWillMount() {
        this.getEmails();
    }

    onSave = (email, additionalEmails, userRole) => {
        const emailBase64 = btoa(email);
        const ref = Gen.getDatabase().ref(`${Constants.USERS}/${emailBase64}`);
        additionalEmails = additionalEmails.split(",").map(e => e.trim()).filter(e => e && e.length > 0);
        ref.once('value', function (snapshot) {
            if (snapshot.exists()) {
                const snapshotVal = snapshot.val();
                const {status} = snapshotVal;

                if (status !== Constants.DELETED) {
                    alert("User already exists");
                    return;
                }
            }
            Gen.getDatabase().ref(`${Constants.USERS}/${emailBase64}`).set({
                status: Constants.INVITED,
                expoToken: '',
                userRole,
                additionalEmails
            })
        });
    };

    renderEventInput = () => {
        this.setState({showAddEmailInput: true})
    }
    addEmail = () => {
        if (this.refEmail.value) {
            this.setState({showAddEmailInput: false})
            this.onSave(this.refEmail.value, this.refAdditionalEmail.value, this.refUserType.value)
        } else
            alert('please enter an email to add');
    }

    deleteEmail = (index) => {
        if (this.state.emails.length === 1) {
            alert("You can't delete the last user");
            return;
        }
        const email = this.state.emails[index].email;
        const emailbase64 = btoa(email);

        Gen.getDatabase().ref(`${Constants.USERS}/${emailbase64}`).update({
            status: Constants.DELETED
        });
    }

    render() {
        const {emails, showAddEmailInput} = this.state;
        return (
            <section className="contactPage_wrap">
                {this.head()}
                <InternalTextBanner Heading="Manage Users" wrapperClass="contact"/>
                <ReactCSSTransitionGroup transitionName="anim" transitionAppear={true} transitionAppearTimeout={5000}
                                         transitionEnter={false} transitionLeave={false}>
                    <div className="main anim-appear">
                        <div className="grid">
                            <div className="column column_12_12">
                                <ul className="list-group">
                                    {
                                        emails.map((data, key) => {
                                            const {email, status, userRole, additionalEmails=[]} = data;

                                            return <EmailComponent key={key} index={key} deleteEmail={this.deleteEmail}
                                                                   email={email + " | " + (userRole || Constants.USER_ROLE.STAFF) + " | " + additionalEmails }/>
                                        })
                                    }
                                </ul>
                                <div>
                                    {showAddEmailInput && <div>
                                        <input className="list-group-item" ref={(ref) => this.refEmail = ref}
                                               style={{width: '100%'}} placeholder="enter email"/>
                                        <input className="list-group-item" ref={(ref) => this.refAdditionalEmail = ref}
                                               style={{width: '100%'}}
                                               placeholder="Enter Additional emails separated by comma. for example a@gmail.com, b@gmail.com"/>
                                        <select className="list-group-item" ref={(ref) => this.refUserType = ref}
                                                name="User Role">
                                            <option value={Constants.USER_ROLE.STAFF}>Internal Staff</option>
                                            <option
                                                value={Constants.USER_ROLE.DEALER_A}>{Constants.USER_ROLE.DEALER_A}</option>
                                            <option
                                                value={Constants.USER_ROLE.DEALER_B}>{Constants.USER_ROLE.DEALER_B}</option>
                                            <option
                                                value={Constants.USER_ROLE.DEALER_SPECIALIST_A}>{Constants.USER_ROLE.DEALER_SPECIALIST_A}</option>
                                            <option
                                                value={Constants.USER_ROLE.DEALER_SPECIALIST_B}>{Constants.USER_ROLE.DEALER_SPECIALIST_B}</option>
                                            <option value={Constants.USER_ROLE.ADMIN}>Full Access</option>
                                        </select>
                                    </div>}
                                </div>
                                <button className='primary'
                                        onClick={showAddEmailInput ? this.addEmail : this.renderEventInput}>
                                    {showAddEmailInput ? <div> Add Email </div> :
                                        <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactCSSTransitionGroup>

            </section>

        );
    }
}

export default {
    component: HomePage
};
