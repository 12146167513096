import React from 'react';
import App from './App'
import NotFoundPage from './pages/notFound404Page';
import homePage from "./pages/homePage";
import notificationPage from "./pages/notificationPage";
import loginPage from "./pages/loginPage";
import appFlowPage from "./pages/appFlowPage";

export default [
  {
    path: '/app-flow',
    ...App,
    routes: [
      {
        ...appFlowPage
      }
    ]
  },
  {
    path: '/login',
    ...App,
    routes: [
      {
        ...loginPage
      }
    ]
  },
  {
    path: '/notification',
    ...App,
    routes: [
      {
        ...notificationPage
      }
    ]
  },
  {
    path: '/users',
    ...App,
    routes: [
      {
        ...homePage
      }
    ]
  },
    {
        path: '/',
      exact: true,
        ...App,
        routes: [
            {
                ...homePage
            }
        ]
    },
  {
    path: '/',
    ...App,
    routes: [
      {
        ...NotFoundPage
      }
    ]
  }
];

