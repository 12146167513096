
import React from 'react';

const TextInput = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label className={'input-label'}>{label}</label>
        <div>
            {type==='textarea' ? <textarea rows={2} style={{width:'100%'}} {...input} placeholder={label}/> :
            <input style={{width:'100%'}} {...input} type={type} placeholder={label} />}
        </div>
    </div>
)


export default TextInput;