import React, {Component} from 'react';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    show = () => {
        this.setState({
          loading: true
        })
    };

    hide = () => {
        this.setState({
          loading: false
        })
    };

    render() {
            return <div className={this.state.loading ? 'loader-visible col col-md-6' : 'loader-hidden'} >
                <i className={"fa fa-spinner fa-spin loader"}  />
            </div>
    }

};

export default Loader;