import React from "react";
import CarouselComponent from "./CarouselComponent";
import Lodash from 'lodash'
import {Gen} from "../helpers/gen";
import ToggleableMenu from "./ToggleableMenu";
import SubMenu from "./SubMenu";

const TruckComponent = ({ data }) => {
    data = Lodash.cloneDeep(data);
    data.featureImages = data.featureImages || {};
    data.featuresText = data.featuresText || {};
    data.sharableContent =data.sharableContent|| {};
    let isDoublyNested=Gen.isDoublyNested(data.path);

    return (
		<div className={"truck-wrapper"}>
			<div className={"truck-images"}>
				{data.featureImages &&
				<CarouselComponent slides={Object.values(data.featureImages).map((eachImage)=>eachImage.url)} />
				}
			</div>
			<div className={"content-text"}> {data.contentText} </div>
			<div className={"features-heading"}> FEATURES </div>
				{data.featuresText && Object.values(data.featuresText).map((eachText, index) => (
					<div key={index}> {eachText} </div>
				))}
            <div style={{ flex: 0 }}>
                {isDoublyNested ?
                    Object.keys(data.sharableContent).map((eachTruck, index) =>
                        <ToggleableMenu key={index} truckName={eachTruck} sharableContentForTruck={data.sharableContent[eachTruck]} />
                    )
                    : Object.keys(data.sharableContent).map((eachCategory, index) =>
                        <SubMenu key={index} subHeading={eachCategory} sharableContentInner={data.sharableContent[eachCategory]} />)
                }
            </div>
		</div>
	);
}

export default TruckComponent;
