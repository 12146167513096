import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {SLIDE_UP, XL} from 'react-ladda';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InternalTextBanner from './../components/internalTextBanner';
import Constants from '../constants';
import {Gen} from "../helpers/gen";
import EmailComponent from "../components/emailComponent";
import {notify} from "react-notify-toast";

class NotificationPage extends Component {

  constructor(props) {
    super(props);
    this.setTitleRef = element => {
      this.titleRef = element;
    };

    this.setMessageRef = element => {
      this.messageRef = element;
    };

  }

  head(){
    return (
        <Helmet>
          <title>{`Notification Page`}</title>
        </Helmet>
    );
  }

  sendNotification(title, message, userRole) {

    if(title && message) {
      const newRef = Gen.getDatabase().ref('notifications').push();
      newRef.set({
        title,
        message,
        userRole,
        timestamp: {".sv": 'timestamp'}
      });
      Gen.sendNotification(title, message, userRole);
    } else {
      alert("Please enter both title and message");
    }
  }

  setTitleRef() {

  }

    render() {

      return (
          <section className="contactPage_wrap">
          {this.head()}
            <InternalTextBanner Heading="Notification" wrapperClass="contact" />
            <ReactCSSTransitionGroup transitionName="anim" transitionAppear={true}  transitionAppearTimeout={5000} transitionEnter={false} transitionLeave={false}>
              <div className="main anim-appear">
                    <div className="grid">
                        <div className="column column_12_12">
                          <div className='text-center'>
                            <div className="input-group margin-bottom-10">
                              <span className="input-group-addon" id="basic-addon1">Title</span>
                              <input type="text" className="form-control" placeholder="notification title"
                                     aria-describedby="basic-addon1"
                                     ref={this.setTitleRef}
                              />
                            </div>

                            <div className="input-group margin-bottom-10">
                              <span className="input-group-addon" id="basic-addon1">Message</span>
                              <input type="text" className="form-control" placeholder="notification message"
                                     aria-describedby="basic-addon1"
                                     ref={this.setMessageRef}
                              />
                            </div>

                            <div className="input-group margin-bottom-10">
                              <span className="input-group-addon" id="basic-addon1">User Role</span>
                              <select className="list-group-item" ref={(ref) => this.refUserType = ref} name="User Role">
                                <option value={Constants.USER_ROLE.STAFF}>Internal Staff</option>
                                <option value={Constants.USER_ROLE.DEALER_A}>{Constants.USER_ROLE.DEALER_A}</option>
                                <option value={Constants.USER_ROLE.DEALER_B}>{Constants.USER_ROLE.DEALER_B}</option>
                                <option value={Constants.USER_ROLE.DEALER_SPECIALIST_A}>{Constants.USER_ROLE.DEALER_SPECIALIST_A}</option>
                                <option value={Constants.USER_ROLE.DEALER_SPECIALIST_B}>{Constants.USER_ROLE.DEALER_SPECIALIST_B}</option>
                                <option value={Constants.USER_ROLE.ADMIN}>Full Access</option>
                                <option value={Constants.USER_ROLE.EVERYONE}>{Constants.USER_ROLE.EVERYONE}</option>
                              </select>
                            </div>

                            <button onClick={() => {
                              this.sendNotification(this.titleRef.value, this.messageRef.value, this.refUserType.value);
                              this.titleRef.value = "";
                              this.messageRef.value = "";
                              notify.show('Notification sent', 'success');
                            }} className="btn btn-primary" type="button">
                              Send Notification
                            </button>
                          </div>
                        </div>
                    </div>
              </div>
            </ReactCSSTransitionGroup>
          
          </section>
        
      );
    }
  }

export default {
  component: NotificationPage
};