import React from 'react';
import SubMenu from './SubMenu'
import PropTypes from 'prop-types'
export default class ToggleableMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = { shouldShowSubMenu: false }
    }
    render() {
        const { shouldShowSubMenu } = this.state
        const { sharableContentForTruck, truckName } = this.props
        return (
            <div>
                <div onClick={() => this.setState({ shouldShowSubMenu: !shouldShowSubMenu })} className={"toggleable"}>
                    {truckName}  <i className={shouldShowSubMenu ? "fas fa-chevron-down": "fas fa-chevron-right"}></i>
                </div>
                {shouldShowSubMenu && Object.keys(sharableContentForTruck).map((eachCategory, index) =>
                   <div className={'submenu-container'}>
                    <SubMenu key={index} subHeading={eachCategory} sharableContentInner={sharableContentForTruck[eachCategory]} />
                   </div>
                       )
                }
            </div>
        );
    }
}


ToggleableMenu.propTypes = {
    truckName: PropTypes.string,
    sharableContentForTruck: PropTypes.object,
};
