import React from "react";
import { Field } from "redux-form";
import TextInput from "./TextInput";
const ArrayInputComponent = ({
  fields,
  label,
    type, multipleFields

}) => (
  <ul>
    <li>
      <button type="button" className={"btn btn-info btn-sm"} onClick={() => multipleFields? fields.push({}) : fields.push()}>
          <span style={{fontWeight:'bold'}}> <i className="fas fa-plus"></i> Add {label} </span>
      </button>
    </li>
    { fields && fields.map((eachField, index) => {
      return (
        <li key={index} className={"input-flex"}>
            <div style={{flex:0.94}}>

                {multipleFields ?
                    <div className={'fieldArray'}>
                    <Field
                        name={`${eachField}.name`}
                        type={'text'}
                        component={TextInput}
                        label={"Name"}
                    />
                        <Field
                            name={`${eachField}.url`}
                            type={type}
                            component={TextInput}
                            label={"Url"}
                        />
                    </div>
                    :
                    <Field
                    name={eachField}
                    type={type}
                    component={TextInput}
                    label={label + index}
          />}
            </div>
            <div style={{flex:0.06}}>
                <i
                    onClick={() => fields.remove(index)}
                    className="fas fa-trash-alt"
                />
            </div>
        </li>
      );
    })}
  </ul>
);

export default ArrayInputComponent;
