import React from "react";
import Slider from "react-slick";
import PropTypes from 'prop-types';

export default class CarouselComponent extends React.Component {
  render() {
    const { slides } = this.props;
    const settings = {
      dots: true
    };
    return (
        <div className={"carousel-container"}>
        <Slider {...settings}>
        {slides.map((eachSlide, index) => (
          <div key={index}>
              <img src={eachSlide} className={'slides'} />
          </div>
        ))}
      </Slider>
        </div>
    );
  }
}

CarouselComponent.propTypes = {
    slides: PropTypes.array.isRequired
};