import React from 'react';
import { Gen } from '../helpers/gen'
import PropTypes from 'prop-types'

class SubMenu extends React.Component {
    render() {
        const { subHeading, sharableContentInner } = this.props
        const images = Gen.getArrayFromObject(sharableContentInner['images']);
        const pdfs = Gen.getArrayFromObject(sharableContentInner['pdfs']);
        return <div style={{paddingHorizontal: 10}}>
            <div style={{ color: 'green', paddingVertical: 5 }}> {subHeading} </div>
            {images && images.map((eachOption, index) =>
                (
                        <div key={index} style={{flexDirection: 'row', paddingRight: 20,}}>
                            <a target="_blank" href={eachOption.url} style={{flexWrap: 'wrap', width:'80%'}}>{eachOption.name}</a>
                        </div>
                )
            )}
            {pdfs && pdfs.map((eachOption, index) =>(
                        <div style={{flexDirection: 'row', paddingRight: 20,}}>
                            <a target="_blank" href={eachOption.url} style={{flexWrap: 'wrap',  width:'80%'}}>{eachOption.name}</a>
                        </div>
                )
            )}
        </div>
    }
}

export default SubMenu;

SubMenu.propTypes = {
    subHeading: PropTypes.string,
    sharableContentInner: PropTypes.object
};


