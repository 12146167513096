import React from "react";
import CarouselComponent from "./CarouselComponent";
import Lodash from 'lodash'

const ApplicationComponent = ({ data }) => {
    data = Lodash.cloneDeep(data);
    data.videos = data.videos || {};
    data.featureImages = data.featureImages || {};
    data.featuresText = data.featuresText || {};

    return (
		<div className={"application-wrapper"}>
			<div className={"application-video"}>
				{Object.values(data.videos).map(
					eachField =>
						<video width='100%' height="80%" controls>
							<source src={eachField.url} type="video/mp4"/>
						</video>
				)}
			</div>
			<div className={'content-text'}> {data.contentText} </div>
			<div className={"application-bottom"}>
				<div className="application-images">
					{data.featureImages ?
						<CarouselComponent slides={Object.values(data.featureImages).map((eachImage)=>eachImage.url)} />
						: <div className={"col-center"}> Images are added here </div>}
				</div>
				<div className={"features application-features"}>
					{data.featuresText ? Object.values(data.featuresText).map((eachText, index) => (
						<div key={index}> {eachText} </div>
					)): <div className={"col-center"}> Images are added here </div>}
				</div>
			</div>
		</div>
	);
}

export default ApplicationComponent;
