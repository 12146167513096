import React from "react";
import Lodash from 'lodash'


const PresentationComponent = ({ data }) => {
  data = Lodash.cloneDeep(data);
  const {pdf} = data;
  return (
    <div style={{flexDirection: 'row', paddingRight: 20,}}>
      <a target="_blank" href={pdf} style={{flexWrap: 'wrap',  width:'80%'}}>Open pdf</a>
    </div>
  );
}

export default PresentationComponent;
