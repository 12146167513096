import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmailComponent extends Component {

  render() {
    const { email, index, deleteEmail } = this.props;
    return (
      <li className="list-group-item">{email}
        <button type="button" className="close" data-dismiss="alert" onClick={() => deleteEmail(index)} aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </li>
    )
  };
}

export default EmailComponent;

EmailComponent.propTypes = {
  email: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  deleteEmail: PropTypes.func.isRequired
};