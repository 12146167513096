import React from 'react';
import ReactDOM from 'react-dom';
import './scss/styles.css';
import App from './App';
import thunk from 'redux-thunk';
import axios from 'axios';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import {notify} from 'react-notify-toast';
import {BrowserRouter} from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { renderRoutes } from 'react-router-config';

import reducers from './reducers';
import Routes from './routes';

const axiosInstance = axios.create();

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 401 && !(window.location.href.includes("/login")  || window.location.href.includes("/register") || window.location.href.includes("/reset-password"))) {
        window.location.href = "/login";
    } else if(error.response.status === 400){
        notify.show(error.response.data.error.message, 'error');
    }
    return error;
});

const store = createStore(
    reducers,
    window.INITIAL_STATE,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(axiosInstance)))
);


export default axiosInstance;

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <div>{renderRoutes(Routes)}</div>
        </BrowserRouter>
    </Provider>
    , document.querySelector('#root'));
