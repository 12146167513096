import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InternalTextBanner from '../components/internalTextBanner';
import firebase from 'firebase';
import {notify} from 'react-notify-toast';
import {Gen} from '../helpers/gen';
import Constants from '../constants'


class LoginPage extends Component {


  head(){
    return (
        <Helmet>
          <title>{`Login`}</title>
        </Helmet>
    );
  }

  onLoginPress = (event) => {
    event.preventDefault();
    const email = this.refs.email.value;
    const password = this.refs.password.value;

    if(!email || !password) {
      alert('Please fill all the details');
      return;
    } else if(email !== Constants.ADMIN_EMAIL) {
      alert("This email isn't valid admin email");
      return;
    }
    if (!Gen.validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    // login in firebase
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(data => {
        const email = data.user.email;
        if(email == Constants.ADMIN_EMAIL) {
          // alert(email)
        }
        notify.show("Login successful", 'success');
        this.props.history.push(`/`);
      })
      .catch(function (error) {
        if (error) {
          alert(error.message);
        }
    });

  };

    render() {

      return (
          
          <section className="contactPage_wrap">
          {this.head()}
            <InternalTextBanner Heading="Login" wrapperClass="contact" />
            <ReactCSSTransitionGroup transitionName="anim" transitionAppear={true}  transitionAppearTimeout={5000} transitionEnter={false} transitionLeave={false}>
            <div className="main anim-appear">
                  <div className="grid">
                      <div className="column column_12_12">
                        <div className="content_block">

                          <form className='login-form text-center' onSubmit={this.onLoginPress}>

                            <div className="form_wrap">

                              <div className="form_row">
                                <div className="form_input">
                                  <input
                                    name="email"
                                    ref="email"
                                    type="text"
                                    placeholder="Enter the email"
                                    className="form-input"
                                  />
                                </div>
                              </div>

                              <div className="form_row">
                                <div className="form_input">
                                  <input
                                      name="password"
                                      ref="password"
                                      type="password"
                                      placeholder="Enter the password"
                                      className='form-input'
                                  />
                                </div>
                              </div>

                              <div className="form_row">
                                  <button
                                      type="submit"
                                      className="btn btn-primary"
                                  >
                                      LogIn
                                  </button>
                              </div>

                            </div>

                          </form>

                        </div>
                      </div>
                  </div>
              </div>
              </ReactCSSTransitionGroup>
          
          </section>
        
      );
    }
  }

export default {
  component: withRouter(LoginPage)
};