import React, {Component} from 'react';
import firebase from 'firebase';
import {notify} from 'react-notify-toast';
import {Gen} from '../helpers/gen'

import {Link, NavLink} from 'react-router-dom';


class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    }
  }

  async componentWillMount() {
    const isUserLoggeddIn = await Gen.isUserLoggedIn();
    this.setState({
      isLoggedIn: isUserLoggeddIn
    });
    if(!window.location.href.includes("/login") && !isUserLoggeddIn) {
      window.location.href = "/login";
    }
  }

  onLogoutPress = (e) => {
    e.preventDefault();

    firebase.auth().signOut().then(function() {
      notify.show("Successfully logged out", 'success');
      window.location.href = "/login";
    }, function(error) {
      notify.show('Sign Out Error', 'error');
    });
  }
    render() {
    const {isLoggedIn} = this.state;
        return (
            
            <header className='scrollActive'>
                <div className="wrap">

                    <div className="logo_wrap">
                        <Link to="/">
                            <img src='/assets/logo.png' alt="Logo" className="dark"/>
                        </Link>
                    </div>

                    <div className='nav_wrap'>
                        <nav>
                            <ul>
                              <li className="first">
                                <NavLink activeClassName="active" to="/app-flow">App Flow</NavLink>
                              </li>

                              <li className="">
                                <a activeClassName="active" target="_blank" href="https://console.firebase.google.com/u/0/project/combilift-1/storage/combilift-1.appspot.com/files~2Fadd_your_files_here~2F">Upload Images/Videos</a>
                              </li>

                              <li>
                                  <NavLink activeClassName="active" to="/users">Manage Users</NavLink>
                              </li>
                              <li>
                                <NavLink activeClassName="active" to="/notification">Notification</NavLink>
                              </li>
                              <li className="last">
                                {
                                  isLoggedIn ?
                                    <NavLink onClick={this.onLogoutPress} activeClassName="active" to="/logout">Log out</NavLink>
                                    : <NavLink activeClassName="active" to="/logIn">Log In</NavLink>
                                }
                              </li>
                            </ul>
                        </nav>
                    </div>

                </div>

            </header>
            
        );
    }  
};

export default Header;