
export default {
  TUNNEL_SERVER: 'http://tunnel.combiliftsalesapp.com',
  ADMIN_EMAIL: 'admin@combilift.com',
  USERS: 'users',
  NOTIFICATIONS: 'notifications',
  INVITED: 'invited',
  REGISTERED: 'registered',
  DELETED: 'deleted',
  INACTIVE: 'inactive',
  appName: 'CombiLift',
  USER_ROLE: {
	  ADMIN: 'APP ADMIN',
	  STAFF: 'STAFF',
	  EVERYONE: 'EVERYONE',
	  DEALER_A: 'DEALER_A',
	  DEALER_B: 'DEALER_B',
	  DEALER_SPECIALIST_A: 'DEALER_SPECIALIST_A',
	  DEALER_SPECIALIST_B: 'DEALER_SPECIALIST_B'
  },

    SHARABLE_TRUCK_HEADINGS_IMPERIAL : {
        "AM33E":["620Ah Battery", "775Ah Battery", "Capacity Guidelines", "Aisle Drawing"],
        "AM44E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines", "Aisle Drawing"],
        "AM55E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines"],
        "AM66E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines", "Aisle Drawing"]
    },

    SHARABLE_TRUCK_HEADINGS_METRIC : {
    "AM15E":["620Ah Battery", "775Ah Battery", "930Ah Battery", "Capacity Guidelines", "Aisle Drawing"],
    "AM20E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines", "Aisle Drawing"],
    "AM25E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines"],
    "AM30E":["775Ah Battery", "930Ah Battery", "Capacity Guidelines", "Aisle Drawing"],
    }
}
